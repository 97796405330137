import React from 'react'
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

class Ehelpdesk extends React.Component {
    constructor(props) {
        super(props);
        this.increaseImageWidthNativeTool = this.increaseImageWidthNativeTool.bind(this);
        this.increaseImageWidthManageCommunication = this.increaseImageWidthManageCommunication.bind(this);
        this.increaseImageWidthFastEffortlessProcess = this.increaseImageWidthFastEffortlessProcess.bind(this);
        this.hitButtonLiveDemoeHelpdesk = this.hitButtonLiveDemoeHelpdesk.bind(this);
        this.hitButtonPricingeHelpdesk = this.hitButtonPricingeHelpdesk.bind(this);
    }

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-section-ehelpdesk-try-product-free .app-ehelpdesk-try-product-free-detail .app-ehelpdesk-try-product-free-faqs-onlinedoc a.app-ehelpdesk-try-product-free-faqs-onlinedoc-a-tag").setAttribute("href", new_online_doc_url + "odoc/07-00-00.html");
    // }

	increaseImageWidthNativeTool() {
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .native-tool .native-tool-left').classList.toggle('native-tool-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .native-tool .native-tool-right').classList.toggle('native-tool-right-active');

        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .manage-communication .manage-communication-left').classList.remove('manage-communication-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .manage-communication .manage-communication-right').classList.remove('manage-communication-right-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .fast-effortless-process .fast-effortless-process-left').classList.remove('fast-effortless-process-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .fast-effortless-process .fast-effortless-process-right').classList.remove('fast-effortless-process-right-active');
    }

    increaseImageWidthManageCommunication() {
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .manage-communication .manage-communication-left').classList.toggle('manage-communication-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .manage-communication .manage-communication-right').classList.toggle('manage-communication-right-active');

        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .native-tool .native-tool-left').classList.remove('native-tool-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .native-tool .native-tool-right').classList.remove('native-tool-right-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .fast-effortless-process .fast-effortless-process-left').classList.remove('fast-effortless-process-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .fast-effortless-process .fast-effortless-process-right').classList.remove('fast-effortless-process-right-active');
    }

    increaseImageWidthFastEffortlessProcess() {
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .fast-effortless-process .fast-effortless-process-left').classList.toggle('fast-effortless-process-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .fast-effortless-process .fast-effortless-process-right').classList.toggle('fast-effortless-process-right-active');

        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .native-tool .native-tool-left').classList.remove('native-tool-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .native-tool .native-tool-right').classList.remove('native-tool-right-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .manage-communication .manage-communication-left').classList.remove('manage-communication-left-active');
        document.querySelector('.app-section-ehelpdesk-communicate-customer-enterprise .manage-communication .manage-communication-right').classList.remove('manage-communication-right-active');
    }

    hitButtonLiveDemoeHelpdesk() {
        var data = {};
        data["type"] = "click";
        data["button"] = "liveDemoeHelpdesk";
        
        var checkItemLocalStorage = localStorage.getItem("dataLiveDemoeHelpdesk");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataLiveDemoeHelpdesk", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    hitButtonPricingeHelpdesk() {
        var data = {};
        data["type"] = "click";
        data["button"] = "pricingeHelpdesk";
        
        var checkItemLocalStorage = localStorage.getItem("dataPricingeHelpdesk");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataPricingeHelpdesk", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    render() {
        const { data } = this.props;
        const introduction = data.introduction.nodes;
        const introduction_vi = data.introduction_vi.nodes;
        const native_tool = data.native_tool.nodes;
        const native_tool_vi = data.native_tool_vi.nodes;
        const manage_communication = data.manage_communication.nodes;
        const manage_communication_vi = data.manage_communication_vi.nodes;
        const fast_effortless_process = data.fast_effortless_process.nodes;
        const fast_effortless_process_vi = data.fast_effortless_process_vi.nodes;
        const practical_features_introduction = data.practical_features_introduction.nodes;
        const practical_features_introduction_vi = data.practical_features_introduction_vi.nodes;
        const practical_features_support_portal = data.practical_features_support_portal.nodes;
        const practical_features_support_portal_vi = data.practical_features_support_portal_vi.nodes;
        const practical_features_customer_portal = data.practical_features_customer_portal.nodes;
        const practical_features_customer_portal_vi = data.practical_features_customer_portal_vi.nodes;
        const ehelpdesk_admin_portal = data.ehelpdesk_admin_portal.nodes;
        const ehelpdesk_admin_portal_vi = data.ehelpdesk_admin_portal_vi.nodes;
        const specifications_etask_team_app = data.specifications_etask_team_app.nodes;
        const specifications_etask_team_app_vi = data.specifications_etask_team_app_vi.nodes;
        const ehelpdesk_try_product_free_live_demo = data.ehelpdesk_try_product_free_live_demo.nodes;
        const ehelpdesk_try_product_free_live_demo_vi = data.ehelpdesk_try_product_free_live_demo_vi.nodes;
        const ehelpdesk_try_product_free_faqs_onlinedoc = data.ehelpdesk_try_product_free_faqs_onlinedoc.nodes;
        const ehelpdesk_try_product_free_faqs_onlinedoc_vi = data.ehelpdesk_try_product_free_faqs_onlinedoc_vi.nodes;
        const ehelpdesk_explore_now = data.ehelpdesk_explore_now.nodes;
        const ehelpdesk_explore_now_vi = data.ehelpdesk_explore_now_vi.nodes;

        var customSettingsSupportCustomerPortal = {
            dots: false,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1367,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Layout>
                <SEO title="eHelpdesk" description="Ticket or Issue management system for your enterprise that run on Microsoft Teams. Helpdesk system to improve customer's satisfaction and reliable tool for issue tracking." meta={[{ name: `keywords`, content: ["collaboration tools on Microsoft Teams", "helpdesk system on Microsoft Teams", "ticket management", "issue management"] }]} pathname="/products/ehelpdesk" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-ehelpdesk-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-ehelpdesk-introduction-detail">
                                <Row className="app-ehelpdesk-introduction-row-custom">
                                    <Col className="app-ehelpdesk-introduction-col-custom" xs={12} md={12} lg={5}>
                                        <div className="app-ehelpdesk-introduction-left">
                                            {introduction.map((res) => (
                                                <div className="app-data-en" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                            {introduction_vi.map((res) => (
                                                <div className="app-data-vi" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col className="app-ehelpdesk-introduction-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-ehelpdesk-introduction-right">
                                            <img className="app-ehelpdesk-introduction-img" src="/app-ehelpdesk-introduction.png" alt="eHelpdesk introduction" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-ehelpdesk-communicate-customer-enterprise" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>Communicate with your customers and enhance the reliability of enterprise</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-ehelpdesk-communicate-customer-enterprise-detail">
                                <Row className="app-ehelpdesk-communicate-customer-enterprise-detail-row-custom native-tool">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-ehelpdesk-communicate-customer-enterprise-detail-col-custom native-tool-left" xs={12} md={12} lg={12} xl={5}>
                                        {native_tool.map((res) => (
                                            <div className="app-data-en app-ehelpdesk-communicate-customer-enterprise-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {native_tool_vi.map((res) => (
                                            <div className="app-data-vi app-ehelpdesk-communicate-customer-enterprise-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-ehelpdesk-communicate-customer-enterprise-detail-col-custom native-tool-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-ehelpdesk-communicate-customer-enterprise-right-side">
                                            <img 
                                                className="app-ehelpdesk-communicate-customer-enterprise-native-tool" 
                                                src="/app-ehelpdesk-communicate-customer-enterprise-native-tool.svg" 
                                                alt="app ehelpdesk communicate customer enterprise native tool" 
                                                onClick={this.increaseImageWidthNativeTool} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-ehelpdesk-communicate-customer-enterprise-detail-row-custom manage-communication">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-ehelpdesk-communicate-customer-enterprise-detail-col-custom manage-communication-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-ehelpdesk-communicate-customer-enterprise-left-side">
                                            <img 
                                                className="app-ehelpdesk-communicate-customer-enterprise-manage-communication" 
                                                src="/app-ehelpdesk-communicate-customer-enterprise-manage-communication.svg" 
                                                alt="app ehelpdesk communicate customer enterprise manage communication" 
                                                onClick={this.increaseImageWidthManageCommunication} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-ehelpdesk-communicate-customer-enterprise-detail-col-custom manage-communication-right" xs={12} md={12} lg={12} xl={5}>
                                        {manage_communication.map((res) => (
                                            <div className="app-data-en app-ehelpdesk-communicate-customer-enterprise-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {manage_communication_vi.map((res) => (
                                            <div className="app-data-vi app-ehelpdesk-communicate-customer-enterprise-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-ehelpdesk-communicate-customer-enterprise-detail-row-custom fast-effortless-process">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-ehelpdesk-communicate-customer-enterprise-detail-col-custom fast-effortless-process-left" xs={12} md={12} lg={12} xl={5}>
                                        {fast_effortless_process.map((res) => (
                                            <div className="app-data-en app-ehelpdesk-communicate-customer-enterprise-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {fast_effortless_process_vi.map((res) => (
                                            <div className="app-data-vi app-ehelpdesk-communicate-customer-enterprise-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-ehelpdesk-communicate-customer-enterprise-detail-col-custom fast-effortless-process-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-ehelpdesk-communicate-customer-enterprise-right-side">
                                            <img 
                                                className="app-ehelpdesk-communicate-customer-enterprise-fast-effortless-process" 
                                                src="/app-ehelpdesk-communicate-customer-enterprise-fast-effortless-process.svg" 
                                                alt="app ehelpdesk communicate customer enterprise fast effortless process" 
                                                onClick={this.increaseImageWidthFastEffortlessProcess} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-ehelpdesk-practical-features" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {practical_features_introduction.map((res) => (
                                        <div className="app-data-en app-title" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {practical_features_introduction_vi.map((res) => (
                                        <div className="app-data-vi app-title" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-ehelpdesk-practical-features-detail">
                                <div className="app-ehelpdesk-practical-features-tabs">
                                    <Tabs defaultActiveKey="support-portal">
                                        <Tab className="app-data-en" eventKey="support-portal" title="Support Portal">
                                            <Slider {...customSettingsSupportCustomerPortal}>
                                                {practical_features_support_portal.map((res) => (
                                                    <div className="app-practical-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                        <Tab className="app-data-vi" eventKey="support-portal" title="Support Portal">
                                            <Slider {...customSettingsSupportCustomerPortal}>
                                                {practical_features_support_portal_vi.map((res) => (
                                                    <div className="app-practical-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                        <Tab className="app-data-en" eventKey="customer-portal" title="Customer Portal">
                                            <Slider {...customSettingsSupportCustomerPortal}>
                                                {practical_features_customer_portal.map((res) => (
                                                    <div className="app-practical-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                        <Tab className="app-data-vi" eventKey="customer-portal" title="Customer Portal">
                                            <Slider {...customSettingsSupportCustomerPortal}>
                                                {practical_features_customer_portal_vi.map((res) => (
                                                    <div className="app-practical-features-slider" key={res.id}>
                                                        <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                        <div className="content">
                                                            <h5>{res.frontmatter.title}</h5>
                                                            <h6>{res.frontmatter.description}</h6>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-ehelpdesk-admin-portal" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {ehelpdesk_admin_portal.map((res) => (
                                        <div className="app-data-en app-ehelpdesk-admin-portal-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {ehelpdesk_admin_portal_vi.map((res) => (
                                        <div className="app-data-vi app-ehelpdesk-admin-portal-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-section-ehelpdesk-specifications" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Specifications</h2>
                            </div>
                            <div className="app-ehelpdesk-specifications-detail">
                                <div className="app-ehelpdesk-specifications-detail-header">
                                    <h6>System Specifications for eHelpdesk Team app</h6>
                                </div>
                                {specifications_etask_team_app.map((res) => (
                                    <Row className="app-ehelpdesk-specifications-detail-row-custom" key={res.id}>
                                        <Col className="app-ehelpdesk-specifications-detail-col-custom-child" xs={3} md={3} lg={1}>
                                            <h6>{res.frontmatter.title}</h6>
                                        </Col>
                                        <Col className="app-ehelpdesk-specifications-detail-col-custom-child" xs={9} md={9} lg={11}>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Container>
                    </div> */}
                    <div className="app-general-seciton app-section-ehelpdesk-try-product-free" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-ehelpdesk-try-product-free-detail">
                                <Row>
                                    {/* {ehelpdesk_try_product_free_live_demo.map((res) => (
                                        <Col xs={12} md={12} lg={4} key={res.id}>
                                            <div className="app-ehelpdesk-try-product-free-live-demo">
                                                <h4>{res.frontmatter.title}</h4>
                                                <h6><Link to="/products/demosite" onClick={this.hitButtonLiveDemoeHelpdesk}>Try It Now !</Link></h6>
                                            </div>
                                        </Col>
                                    ))} */}
                                    {ehelpdesk_try_product_free_faqs_onlinedoc.map((res) => (
                                        <Col xs={12} md={12} lg={12} key={res.id} className="app-data-en">
                                        {/* <Col xs={12} md={12} lg={4} key={res.id}> */}
                                            <div className="app-ehelpdesk-try-product-free-faqs-onlinedoc">
                                                <h4>{res.frontmatter.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                    {ehelpdesk_try_product_free_faqs_onlinedoc_vi.map((res) => (
                                        <Col xs={12} md={12} lg={12} key={res.id} className="app-data-vi">
                                        {/* <Col xs={12} md={12} lg={4} key={res.id}> */}
                                            <div className="app-ehelpdesk-try-product-free-faqs-onlinedoc">
                                                <h4>{res.frontmatter.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-section-ehelpdesk-explore-now" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        {ehelpdesk_explore_now.map((res) => (
                            <div key={res.id}>
                                <div className="app-title">
                                    <h4>{res.frontmatter.title}</h4>
                                </div>
                                <div className="app-section-ehelpdesk-explore-now-detail">
                                    <h6><Link to="/pricing" onClick={this.hitButtonPricingeHelpdesk}>Subscribe</Link></h6>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </Layout>
        )
    }
}

export default Ehelpdesk

export const query = graphql`
    query eHelpdeskPage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
		native_tool: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_native_tool" } } }
        ) {
            nodes {
                html
                id
            }
        }
		native_tool_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_native_tool_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        manage_communication: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_manage_communication" } } }
        ) {
            nodes {
                html
                id
            }
        }
        manage_communication_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_manage_communication_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        fast_effortless_process: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_fast_effortless_process" } } }
        ) {
            nodes {
                html
                id
            }
        }
        fast_effortless_process_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_fast_effortless_process_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_practical_features_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_practical_features_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        practical_features_support_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_practical_features_support_portal" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        practical_features_support_portal_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_practical_features_support_portal_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        practical_features_customer_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_practical_features_customer_portal" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        practical_features_customer_portal_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_practical_features_customer_portal_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        ehelpdesk_admin_portal: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_admin_portal" } } }
        ) {
            nodes {
                html
                id
            }
        }
        ehelpdesk_admin_portal_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_admin_portal_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        specifications_etask_team_app: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_specifications_etask_team_app" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        specifications_etask_team_app_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_specifications_etask_team_app_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        ehelpdesk_try_product_free_live_demo: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_try_product_free_live_demo" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        ehelpdesk_try_product_free_live_demo_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_try_product_free_live_demo_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        ehelpdesk_try_product_free_faqs_onlinedoc: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_try_product_free_faqs_onlinedoc" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        ehelpdesk_try_product_free_faqs_onlinedoc_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_try_product_free_faqs_onlinedoc_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        ehelpdesk_explore_now: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_explore_now" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        ehelpdesk_explore_now_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "ehelpdesk_explore_now_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
    }
`;
